(function ($, app, _) {
  'use strict';
  app.m67 = function () {
    if( $('section.m67').length == 0 ) return

    // trigger click, if hash is set
    if (window.location.hash) {
        var campId = window.location.hash.replace('#', '')
        var $target = $('.m67_camp[data-camp-id="' + campId + '"]')
        $target.toggleClass('open')
        $('.m67_filter').attr('data-state', 'closed')
        window.scrollTo({ top: 0, left: 0 })
        
        setTimeout(() => {
          var container = $('.m67_list')[0]
          var target    = $target[0]
          var offset    = target.offsetTop - container.offsetTop
          var height    = $('.m67_filter_toggle').height()
          container.scrollTo({ top: offset - height + 2, left: 0, behavior: "smooth" })
        }, 400);

        setTimeout(() => {
          panToMarkerById($target.attr('data-id'))
        }, 1500);
    }

    // toggle more filters
    $('.m67_filter_more').on('click', function() {
      let state = $('.m67_filter').attr('data-state')
          state = (state == 'all') ? 'open' : 'all'

      $('.m67_filter').attr('data-state', state)
    })

    // toggle filters
    $('.m67_filter_toggle').on('click', function() {
      $('.m67_filter').attr('data-state', 'open')
    })

    // toggle map
    $('.m67_toggle').on('click', function() {
      $('.m67_map').toggleClass('visible')
      $('.m67_toggle').toggleClass('close')
    })

    // position tooltop
    $('.m67_tooltip').on('mouseover', function() {
      var elementRect = $(this)[0].getBoundingClientRect()
      var parentRect  = $(this).parents()[0].getBoundingClientRect()

      var parentCenterX = parentRect.left + (parentRect.width / 2)
      var elementCenterX = elementRect.left + (elementRect.width / 2)
      var distanceFromCenter = elementCenterX - parentCenterX
      var positionPercent = (distanceFromCenter / parentRect.width) * 100
      
      var position = 'left'
      if( Math.abs(positionPercent) < 25 ) position = 'center'
      if( positionPercent > 25 ) position = 'right'

      $(this).attr('data-position', position)
    })

    // toggle camp
    $('.m67_camp_toggle').on('click', function() {
      var $target = $(this).parents('.m67_camp')
      $target.toggleClass('open')

      if($target.hasClass('open')) {
        panToMarkerById($target.attr('data-id'))
      } 

      if( $('.m67_camp.open').length > 0 ) {
        $('.m67_filter').attr('data-state', 'closed')
      }
    });
  };
})(jQuery, app, _);
